/* global window */

export const BASE_URL = process.env.REACT_APP_API_URL;

export const getToken = () => {
  return window.localStorage.getItem('jwt.token');
};

export const setToken = newToken => {
  window.localStorage.setItem('jwt.token', newToken);
};

export const removeToken = () => {
  window.localStorage.removeItem('jwt.token');
};

const abstractRequest = (endpoint, { headers = {}, body, ...otherOptions }, method) => {
  let base = BASE_URL;
  if (process.env.NODE_ENV === 'development') {
    if (endpoint.includes('elastic')) {
      base = 'http://127.0.0.1:8001/api';
    }
  }

  return fetch(`${base}${endpoint}`, {
    ...otherOptions,
    headers: {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: body ? JSON.stringify(body) : undefined,
    method
  }).then(response => {
    if (response.status !== 200) {
      return Promise.reject(response);
    }
    return response.json();
  });
};

const checkForRefreshToken = (endpoint, content, method) => response => {
  if (response && response.status === 401 && getToken()) {
    return abstractRequest('/auth/refresh', {}, 'post').then(({ access_token }) => {
      setToken(access_token);
      return abstractRequest(endpoint, content, method);
    });
  }

  return Promise.reject(response);
};

const checkForRelogin = response => {
  if (response && response.status === 401) {
    const hadToken = getToken() !== null;

    // If there was a token, but an invalid one we will reload to the root.
    // We remove the token, because we have already tried to refresh it.
    // Otherwise this means there was no token, so we are probably on the login page.
    if (hadToken) {
      removeToken();

      window.location.href = '/';
    }
  }

  return Promise.reject(response);
};

const request = (endpoint, content, method) => {
  return abstractRequest(endpoint, content, method)
    .catch(checkForRefreshToken(endpoint, content, method))
    .catch(checkForRelogin);
};

export const api = {
  get(endpoint, options = {}) {
    return request(endpoint, options, 'get');
  },
  post(endpoint, options = {}) {
    return request(endpoint, options, 'post');
  },
  put(endpoint, options = {}) {
    return request(endpoint, options, 'put');
  }
};

export default api;
