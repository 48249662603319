import React, { useState } from 'react';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { classNames } from '../utils/utils';

import './LazyLoadImage.css';

// This will load the image in the background
const loadImage = (src, setIsLoaded) => {
  const image = new window.Image();

  image.onload = () => setIsLoaded(true);

  image.src = src;
};

const LazyLoadImage = ({ placeholder, src, alt, className }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [rootRef, isIntersecting] = useIntersectionObserver();

  // If not loading, and it is intersecting, start loading the image
  if (!isLoading && isIntersecting) {
    setIsLoading(true);
    loadImage(src, setIsLoaded);
  }

  if (isLoaded) {
    return <img ref={rootRef} className={className} src={src} alt={alt} />;
  }

  return (
    <img
      ref={rootRef}
      className={classNames(className, 'LazyLoadImage-lazy')}
      src={placeholder}
      alt={alt}
    />
  );
};

export default LazyLoadImage;
