import React, { useState } from 'react';

import api, { setToken } from '../../utils/api';
import heroImage from './hero.png';

const login = (onLogin, setBusy, setError, credentials) => {
  setBusy(true);
  api
    .post('/auth/login', { body: credentials })
    .then(({ access_token }) => {
      setToken(access_token);
      onLogin();
    })
    .catch(() => {
      setBusy(false);
      setError(true);
    });
};

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const [busy, setBusy] = useState(false);

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="flex flex-wrap w-4/5">
        <div className="w-full md:w-1/2 pt-8 pl-16">
          <div className="text-4xl font-light text-black mb-6">Davy Coghe</div>
          {error && <p className="mt-1 font-light mb-4">Het wachtwoord is onjuist</p>}
          <form>
            <div className="text-xs text-gray-800 uppercase mb-1">Gebruikersnaam</div>
            <input
              name="username"
              className="w-4/5 py-2 pr-4 pl-2 mb-4 outline-none border"
              placeholder="Gebruikersnaam"
              type="text"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <div className="text-xs text-gray-800 uppercase mb-1">Wachtwoord</div>
            <input
              name="password"
              className="w-4/5 py-2 pr-4 pl-2 mb-4 outline-none border"
              placeholder="Wachtwoord"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <button
              className="block font-light px-2 py-1 border focus:outline-none"
              type="button"
              disabled={busy}
              onClick={() => login(onLogin, setBusy, setError, { username, password })}
            >
              Inloggen
            </button>
          </form>
        </div>
        <div className="w-full hidden md:block md:w-1/2">
          <div className="w-full h-full flex items-center">
            <img
              className="Login__HeroImage"
              src={heroImage}
              alt="Simple overview of the product"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Login.defaultProps = {
  onLogin: () => {}
};

export default Login;
