import React from 'react';

const defaultOptions = {
  root: null,
  rootMargin: '125px',
  threshold: 0
};

export const useIntersectionObserver = (callback = () => {}, options = defaultOptions) => {
  const [isIntersecting, setIntersecting] = React.useState(false);
  const rootRef = React.useRef();

  const observer = new IntersectionObserver(([entry]) => {
    if (isIntersecting != entry.isIntersecting) {
      setIntersecting(!isIntersecting);
    }
  }, options);

  // When you call useEffect, you’re telling React to run your “effect”
  // function after flushing changes to the DOM. Similar to
  // componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    observer.observe(rootRef.current);

    // Cleanup
    return () => observer.unobserve(rootRef.current);
  });

  return [rootRef, isIntersecting];
};

export default useIntersectionObserver;
