import React from 'react';
import Search from './Search';
import api, { removeToken } from '../utils/api';
import Icon from './Icon';

const logout = () => {
  api.post('/auth/logout').then(() => {
    removeToken();
    window.location = '/';
  });
};

const Header = ({ search, user }) => (
  <div className="flex justify-center py-3 border-b font-light">
    <div className="w-5/6 flex items-center justify-between">
      <a href="/" className="no-underline text-xl text-black">
        Davy Coghe
      </a>
      <Search search={search} />
      <button
        className="font-light flex flex-no-shrink items-center focus:outline-none"
        onClick={() => logout()}
      >
        <span>Welkom, {user && user.username}</span>
        <Icon name="chevron-right" className="w-5 h-5 pt-px" />
      </button>
    </div>
  </div>
);

export default Header;
