import React, { Component } from 'react';

import Header from './Header';
import ImageGrid from './ImageGrid';
import SubTitle from './SubTitle';
import Login from './Login';
import api from '../utils/api';
import Spinner from './Spinner';
import Center from './Center';
import { sleep } from '../utils/utils';

class App extends Component {
  state = {
    authenticated: true,
    loading: true,
    error: undefined,
    currentSearch: '',
    images: [],
    total: 0,
    user: undefined
  };

  componentDidMount = () => {
    this.onLogin();
  };

  onLogin = () => {
    this.setState({ authenticated: true, loading: true }, () => {
      api
        .get('/auth/me')
        .then(user => this.setState({ user }))
        .then(() => Promise.all([sleep(1000), this.loadImages()]))
        .then(() => this.setState({ loading: false }))
        .catch(() => this.setState({ loading: false, authenticated: false }));
    });
  };

  search = value => {
    this.setState({ error: undefined, currentSearch: value }, () => this.loadImages());
  };

  loadImages = (page = 1) => {
    const offset = (page - 1) * this.props.perPage;
    const { currentSearch } = this.state;

    const url = currentSearch ? '/elastic/search' : '/elastic/latest';

    api
      .get(`${url}?query=${currentSearch}&from=${offset}`)
      .then(({ total, hits }) => this.setState({ total, images: hits }))
      .catch(() => {
        this.setState({
          error: 'Oops. Er is iets fout gegaan bij het ophalen van de resultaten.',
          images: []
        });
      });
  };

  render() {
    const { currentSearch, images, total, error, loading, authenticated, user } = this.state;

    if (loading) {
      return (
        <Center>
          <Spinner />
        </Center>
      );
    }

    if (!authenticated) {
      return <Login onLogin={this.onLogin} />;
    }

    return (
      <div className="App font-sans">
        <Header search={this.search} user={user} />
        <div className="flex flex-col items-center">
          <div className="w-5/6 mt-12 mb-12">
            {error && <SubTitle>{error}</SubTitle>}
            <ImageGrid
              perPage={this.props.perPage}
              currentSearch={currentSearch}
              images={images}
              total={total}
              load={this.loadImages}
            />
          </div>
        </div>
      </div>
    );
  }
}

App.defaultProps = {
  perPage: 48
};

export default App;
