export const classNames = (...classes) => {
  return classes.filter(className => !!className).join(' ');
};

export const chunk = (array, size) => {
  const length = array == null ? 0 : array.length;

  if (!length || size < 1) {
    return [];
  }

  const result = [];
  let index = 0;
  let resIndex = 0;

  while (index < length) {
    result[resIndex++] = array.slice(index, (index += size));
  }

  return result;
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
