import React from 'react';

import './Icon.css';
import { classNames } from '../utils/utils';

import { ReactComponent as chevronRight } from '../icons/ei-chevron-right.svg';

const icons = {
  'chevron-right': chevronRight
};

const Icon = ({ name, className = 'w-5 h-5' }) => {
  const Component = icons[name];
  return (
    <div className={classNames(className, 'icon')}>
      <Component />
    </div>
  );
};

export default Icon;
