import React, { useState } from 'react';
import { Dialog } from '@reach/dialog';

import Image from '../Image';
import AspectRatioBox from '../AspectRatioBox';

import { BASE_URL, getToken } from '../../utils/api';
import Pagination from '../Pagination';
import usePrevious from '../../hooks/usePrevious';
import { classNames } from '../../utils/utils';

const ImageGrid = ({ perPage, currentSearch, total, images = [], load }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);
  const [currentPhotoPreview, setCurrentPhotoPreview] = useState(false);

  const dynamicallyDownloadPhoto = href => {
    const link = document.createElement('a');

    link.href = `${BASE_URL}/photos/download/${currentPhotoPreview._id}/${
      currentPhotoPreview._source.name
    }?token=${getToken()}`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const previousSearch = usePrevious(currentSearch);

  if (currentSearch !== previousSearch && currentPage !== 1) {
    setCurrentPage(1);
  }

  return (
    <>
      <div className="flex flex-wrap">
        {images.map(image => (
          <div className="w-full sm:w-1/2 md:w-1/3 p-3" key={image._id}>
            <AspectRatioBox>
              <button
                className="w-full h-full text-left"
                onClick={() => setCurrentPhotoPreview(image)}
              >
                <Image
                  src={`${BASE_URL}/photos/thumbnail/xl/${image._id}?token=${getToken()}`}
                  placeholder={`${BASE_URL}/photos/thumbnail/s/${image._id}?token=${getToken()}`}
                  name={image._source.name}
                  source={image._source}
                />
              </button>
            </AspectRatioBox>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <Pagination
          total={total}
          perPage={perPage}
          currentPage={currentPage}
          setPage={page => {
            load(page);
            window.scrollTo(0, 0);
            setCurrentPage(page);
          }}
        />
      </div>
      <Dialog
        isOpen={currentPhotoPreview && true}
        onDismiss={() => {
          setCurrentPhotoPreview(false);
          setIsDownloading(false);
        }}
      >
        {currentPhotoPreview && (
          <div className="flex flex-col items-center">
            <img
              className="rounded-sm"
              src={`${BASE_URL}/photos/thumbnail/xl/${currentPhotoPreview._id}?token=${getToken()}`}
              alt={currentPhotoPreview._source.name}
              source={currentPhotoPreview._source}
              style={{
                maxHeight: '80vh',
                maxWidth: '80vh'
              }}
            />
            <button
              className={classNames(
                'mt-2 px-4 py-2 bg-gray-900 font-light text-white border opacity-75 focus:outline-none pointer-events-auto',
                isDownloading && 'cursor-not-allowed'
              )}
              onClick={() => {
                setIsDownloading(true);
                dynamicallyDownloadPhoto();
              }}
              disabled={isDownloading}
            >
              {isDownloading ? 'Downloading...' : 'Download'}
            </button>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default ImageGrid;
