import React from 'react';

import './AspectRatioBox.css';

const AspectRatioBox = ({ children }) => (
  <div className="AspectRatioBox">
    <div className="AspectRatioBox__Inside">{children}</div>
  </div>
);

export default AspectRatioBox;
